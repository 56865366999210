<template>
  <div class="savings">
    <h1>Saving with Cash Box</h1>
    <b-card-group deck>
      <b-card
          class="mb-3"
          img-src="/tiles/cash.png"
          img-alt="Savings"
          img-top
          title="Cash Box offers simple savings accounts"
      >
        <ul>
          <li>Normal shares</li>
          <li>Christmas Savings</li>
          <li>Junior savings</li>
        </ul>
        <p>
          <a class="btn btn-lg btn-success text-uppercase" href="https://cashbox.cuaccount.com/join/" target="_blank">Join Cash Box</a>
        </p>
      </b-card>
      <b-card
          class="mb-3"
          img-src="/tiles/coins.png"
          img-alt="Savings"
          img-top
          title="How to withdraw your money"
      >
        <p>You can access your money at any time - although we encourage you to save for a pre-arranged target date, you can take your money out at any time* </p>
        <p>*Subject to your savings not being attached to loans for security.</p>
        <p>*Not including Christmas Savings.</p>
        <p>Log into your account to request a share withdrawal.</p>
        <p>
          <a href="https://cashbox.cuaccount.com/login/" target="_blank" class="btn btn-primary mr-2">Existing Members</a>
        </p>
      </b-card>
    </b-card-group>
  </div>
</template>

<script>
export default {
  name: 'Savings',
  metaInfo() {
    return {
      title: 'Savings | Cash Box Credit Union',
      meta: [
        { name: 'keywords', content: "" },
        { name: 'description', content: "" }
      ],
    };
  }
}
</script>

<style scoped>

</style>
